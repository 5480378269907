@import 'main.scss';
.dialogText {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .titleDescription {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    h2 {
      font-family: var(--font-avant);
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
      text-transform: uppercase;
      color: var(--primary);
      padding-right: 72px;
      -webkit-text-stroke: 1px var(--primary);

      @media screen and (min-width: 1000px) {
        padding-right: 0;
        font-size: 50px;
        line-height: 60px;
      }

      @media screen and (min-width: 1200px) {
        font-size: 60px;
        line-height: 70px;
      }

      @media screen and (min-width: 1600px) {
        font-size: 70px;
        line-height: 80px;
      }
    }

    p {
      font-family: var(--font-sora);
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;

      @media screen and (min-width: 1000px) {
        font-size: 26px;
        line-height: 33px;
      }
    }
  }

  .formInputContainer {
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media screen and (min-width: 1000px) {
      margin-top: 30px;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 24px;
    }

    label {
      font-size: 16px;
      line-height: 21px;
      color: var(--black);
      font-family: var(--font-sora);
      font-weight: 300;
      -webkit-transform: translateY(50%);
      transform: translateY(50%);
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      position: absolute;
      pointer-events: none;
      left: 16px;
      width: 90%;
      top: 50%;
      transform: translateY(-50%);
    }

    .inputContainer {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      position: relative;
      align-items: flex-end;
      font-family: var(--font-sora);

      input {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #8c8c8c;
        font-size: 18px;
        line-height: 24px;
        transition: all 0.3s;
        outline: none;
        box-sizing: border-box;
        position: relative;
        user-select: none;
      }

      .errorMessage {
        position: absolute;
        content: "";
        bottom: -15px;
        left: 0px;
        color: red;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
      }

      .borderError {
        border: 1px red solid;
      }

      .errorLabel {
        color: red;
      }

      input:focus + label,
      input.noEmpty + label {
        width: 125px;
        top: 2px;
        left: 16px;
        font-size: 11px;
        font-weight: 500;
        transform: translateY(0%);

        &.errorLabel {
          transition: all 0.3s;
          color: red;
        }
      }
    }

    input,
    textarea {
      cursor: none;
      height: 52px;
      padding: 15px 16px 5px;
      gap: 10px;
      border-radius: 5px;
      border: 1px solid #8c8c8c;
      font-size: 16px;
      line-height: 20px;
      font-family: var(--font-sora);
    }

    &:nth-child(1) input {
      grid-area: 1 / 1 / 2 / 2;
    }

    &:nth-child(2) input {
      grid-area: 1 / 2 / 2 / 3;
    }

    .areaContainer {
      grid-area: 3 / 1 / 3 / 3;
      position: relative;
      background-color: var(--white);

      textarea {
        top: 0px;
        height: 100px;
        width: 100%;
        resize: none;
        position: relative;
        padding: 23px 16px 5px;

        &::-webkit-scrollbar {
          width: 12px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 40px;
          background-color: rgb(155, 155, 155);

          border: 3px solid var(--white);
        }
      }

      textarea + label {
        top: 28px;

        @media screen and (min-width: 1000px) {
          top: 16px;
        }
      }

      textarea:focus + label,
      textarea.noEmpty + label {
        width: calc(100% - 32px);
        left: 16px;
        top: 1px;
        font-size: 11px;
        font-weight: 500;
        transform: translateY(0%);
        background: #fff;
      }
    }
  }
}

.dialogButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
